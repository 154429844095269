export default {
  AdditionalPageTypes: true,
  Admin: true,
  Advertising: false,
  Advertising2: {
    adServers: ["simple"],
    adSizes: ["mpu", "banner", "banner-large"],
  },
  AggregatedContentFeed: false,
  AllowCustomFields: false,
  AppConfig: false,
  AppEcommerceManager: false,
  AppScorePredictor: false,
  AppSocialManager: false,
  AppTicketingManager: false,
  BreakingNews: false,
  BuildManager: true,
  Calendar: false,
  CustomCommentary: true,
  CustomLineups: true,
  CustomTables: true,
  CustomTags: false,
  Dashboard: true,
  Fixtures: true,
  Forms: true,
  HomepageManager: true,
  HomepageNewsPromo: false,
  HomepagePlayerHighlight: true,
  Lab: false,
  MapDirectory: true,
  MatchdayTakeover: true,
  Media: true,
  News: true,
  OneFootballIntegration: true,
  OptaIntegration: true,
  Organisations: false,
  OverlayPromos: true,
  Pages: true,
  PlatformOptions: true,
  PlayerVoting: false,
  PreventEntitlementEmails: false,
  Profile: true,
  RelatedContentTagging: true,
  RewardsManager: false,
  RewardPoints: false,
  Rover: false,
  Settings: false,
  SiteConfig: true,
  Sponsors: true,
  Squads: true,
  SsoUsers: true,
  StreamAMGIntegration: false,
  Streamline: false,
  StreamlineAdverts: false,
  StreamlinePromoCodes: false,
  Videos: false,
  Voice: false,
  WebStats: true,
};
